import ApiService from "../../../core/services/api.service";

const apiService = ApiService;

export default class RoleService {
  #api = null;

  constructor() {
    this.#api = `admin/role`;
  }

  paginate() {
    let url = `${this.#api}`;
    return apiService.get(url);
  }

  all(except = false) {
    let url = `${this.#api}`;
    if (except === true) url = `${this.#api}?except=true`;
    return apiService.get(url);
  }

  update(id, data) {
    let url = `${this.#api}/${id}`;
    return apiService.put(url, data);
  }

  store(data) {
    let url = `${this.#api}`;
    return apiService.post(url, data);
  }

  show(id) {
    let url = `${this.#api}/${id}`;
    return apiService.get(url);
  }

  delete(id) {
    let url = `${this.#api}/${id}`;
    return apiService.delete(url);
  }

  setDefault(id) {
    let url = `${this.#api}/${id}/default-permission`;
    return apiService.get(url);
  }

  assignPermission(id, data) {
    let url = `${this.#api}/${id}/assign-permission`;
    return apiService.post(url, data);
  }

  removePermission(id, data) {
    let url = `${this.#api}/${id}/remove-permission`;
    return apiService.post(url, data);
  }

  rolePermissions(id) {
    let url = `${this.#api}/${id}/permissions`;
    return apiService.get(url);
  }
}
