<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Manage Admin Users'">
          <template v-slot:preview>
            <v-card>
              <v-card-title>
                <v-row align="center">
                  <v-col>
                    <v-select
                      class="ml-3"
                      v-model="userType"
                      :items="userItems"
                      item-text="type"
                      item-value="type"
                      label="User Type"
                      autocomplete="off"
                      @change="getSelectUser($event)"
                      :disabled="currentUser.user_type === 'Staff'"
                      outline
                      dense
                    ></v-select>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col>
                    <v-text-field
                      v-model="search"
                      append-icon="search"
                      label="Search"
                      single-line
                      hide-details
                      autocomplete="off"
                      outline
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-title>
              <template>
                <v-data-table
                  :headers="headers"
                  :items="users"
                  :search="search"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        dark
                        class="mb-2"
                        @click="createAdmin"
                      >
                        New Add
                      </v-btn>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.image_path="{ item }">
                    <v-img
                      v-if="item.image_path"
                      :src="item.image_path.thumb"
                      width="50"
                      height="50"
                    ></v-img>
                    <v-img
                      v-else
                      :src="require('@/assets/images/no-image-2.png')"
                      width="50"
                      height="50"
                    ></v-img>
                  </template>
                  <template v-slot:item.is_active="{ item }">
                    <v-chip
                      :color="item.is_active == 0 ? 'red' : 'green'"
                      small
                      outlined
                    >
                      {{ item.is_active == 0 ? "InActive" : "Active" }}
                    </v-chip>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <div class="text-center">
                      <v-menu left offset-x>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            icon
                            dark
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon>mdi-dots-horizontal</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item
                            class="cursor-pointer blue--text"
                            @click="editAdmin(item)"
                          >
                            <v-list-item-title>
                              <v-icon color="blue darken-2" small class="mr-2">
                                mdi-pencil </v-icon
                              >Edit
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            class="cursor-pointer blue--text"
                            @click="viewProfile(item.id)"
                            v-if="item.id !== currentUser.id"
                          >
                            <v-list-item-title>
                              <v-icon
                                color="blue darken-2"
                                small
                                class="mr-2"
                                v-if="item.id !== currentUser.id"
                              >
                                mdi-eye
                              </v-icon>
                              View Profile
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            class="cursor-pointer blue--text"
                            @click="viewUserAccess(item)"
                            v-if="checkIsAccessible('role', 'user-access')"
                          >
                            <v-list-item-title>
                              <v-icon color="blue darken-2" small class="mr-2">
                                mdi-account-key
                              </v-icon>
                              User Access
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            class="cursor-pointer red--text"
                            @click="confirmDelete(item.id)"
                          >
                            <v-list-item-title>
                              <v-icon color="red darken-2" small class="mr-2">
                                mdi-delete
                              </v-icon>
                              Delete
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                  </template>
                </v-data-table>
              </template>
            </v-card>
          </template>
        </KTCodePreview>
        <add-admin-user
          ref="create-or-update"
          :dialog="dialog"
          @close-modal="close"
        ></add-admin-user>
        <user-access ref="user-access"></user-access>
      </div>
    </div>
  </div>
</template>

<script>
import KTCodePreview from "@/view/content/CodePreview.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import AdminUserService from "../../../service/user/AdminUserService";
import AddAdminUser from "./CreateOrUpdate";
import UserAccess from "@/view/pages/admin/UserAccess";

const adminUserService = new AdminUserService();

export default {
  name: "ManageAdminUser",
  components: {
    UserAccess,
    AddAdminUser,
    KTCodePreview
  },
  data() {
    return {
      dialog: false,
      search: "",
      users: [],
      userType: "All",
      currentUser: {},
      userItems: ["All", "Admin", "Staff"],
      headers: [
        { text: "Image", align: "left", sortable: false, value: "image_path" },
        {
          text: "Name",
          align: "left",
          value: "name"
        },
        { text: "Email", sortable: false, value: "email" },
        { text: "Primary address", sortable: false, value: "address_line_1" },
        { text: "Secondary address", sortable: false, value: "address_line_2" },
        { text: "Contact", sortable: false, value: "contact" },
        { text: "User Type", sortable: false, value: "user_type" },
        { text: "Status", sortable: false, value: "is_active" },
        { text: "Actions", sortable: false, value: "actions" }
      ]
    };
  },
  mounted() {
    this.currentUser = this.$store.getters.currentUser;
    if (this.currentUser && this.currentUser.user_type === "Staff") {
      this.getSelectUser("Staff");
    } else {
      this.getAllUsers();
    }
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Admin Users" },
      { title: "Manage Admin Users" }
    ]);
  },

  methods: {
    close() {
      this.dialog = false;
    },

    viewUserAccess(item) {
      this.$refs["user-access"].openDialog(item);
    },

    viewProfile(id) {
      this.$router.push({ name: "admin-profile", params: { id: id } });
    },

    createAdmin(user) {
      this.dialog = true;
      this.$refs["create-or-update"].createAdmin(user);
    },

    editAdmin(user) {
      this.dialog = true;
      this.$refs["create-or-update"].editAdmin(user);
    },

    confirmDelete(id) {
      this.$confirm({
        message: `Are you Sure ??`,
        button: {
          yes: "Yes",
          no: "No"
        },
        callback: confirm => {
          if (confirm) {
            adminUserService.delete(id).then(response => {
              if (response.data.status === "OK") {
                this.$snotify.success("Delete Successfully");
                this.getAllUsers();
              } else {
                this.$snotify.error("Something Went Wrong");
              }
            });
          }
        }
      });
    },

    getAllUsers() {
      adminUserService
        .all()
        .then(response => {
          this.users = response.data.data;
        })
        .finally(() => {
          return false;
        });
    },

    getAdminUser() {
      adminUserService
        .getAdminUser()
        .then(response => {
          this.users = response.data.data;
        })
        .finally(() => {
          return false;
        });
    },

    getStaff() {
      adminUserService
        .getStaff()
        .then(response => {
          this.users = response.data.data;
        })
        .finally(() => {
          return false;
        });
    },

    getSelectUser(event) {
      if (event === "All") this.getAllUsers();
      else if (event === "Admin") this.getAdminUser();
      else this.getStaff();
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-list-item:hover {
    background: #e9ebf4;
  }
}
</style>
