<template>
  <v-row align="center">
    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">User Access</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form lazy-validation autocomplete="off">
              <v-row>
                <v-col cols="12">
                  <v-select
                    label="User Role"
                    v-model="selected_roles"
                    :items="roles"
                    item-text="name_text"
                    item-value="name"
                    clearable
                    chips
                    deletable-chips
                    multiple
                    outlined
                    dense
                  ></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" dark @click="saveUserAccess">
            Save
          </v-btn>
          <v-btn color="red darken-1" dark @click="closeDialog"> Cancel </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import AdminUserService from "@/service/user/AdminUserService";
import RoleService from "@/service/user/role/RoleService";

const adminUserService = new AdminUserService();
const roleService = new RoleService();

export default {
  name: "UserAccess",
  data() {
    return {
      dialog: false,
      selected_roles: [],
      admin_user: null,
      roles: []
    };
  },
  methods: {
    openDialog(item) {
      this.dialog = true;
      this.admin_user = item;
      this.getAllRoles();
      this.getUserRole();
    },
    closeDialog() {
      this.dialog = false;
    },
    getAllRoles() {
      roleService.all().then(response => {
        this.roles = response.data.data;
      });
    },
    saveUserAccess() {
      this.assignRole();
    },
    getUserRole() {
      adminUserService
        .userRoles(this.admin_user.id)
        .then(response => {
          this.selected_roles = response.data;
        })
        .catch(() => {
          this.roles = [];
        });
    },
    assignRole() {
      adminUserService
        .assignRole(this.admin_user.id, { roles: this.selected_roles })
        .then(() => {
          this.$snotify.success("Successfully assigned role to user");
          this.getUserRole();
          this.closeDialog();
        })
        .catch(() => {
          this.$snotify.error("Something Went Wrong Please Try Again Later");
        });
    }
  }
};
</script>
