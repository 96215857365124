<template>
  <v-app>
    <transition name="fade-in-up">
      <manage-admin-user></manage-admin-user>
    </transition>
  </v-app>
</template>

<style lang="scss" scoped>
.theme--light.v-application {
  background: inherit;
}
</style>

<script>
import ManageAdminUser from "./ManageAdminUsers";
export default {
  name: "Users",
  components: { ManageAdminUser }
};
</script>
