<template>
  <v-row align="center">
    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline"
            >{{ editUser ? "Edit" : "Add" }} Admin User</span
          >
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form lazy-validation autocomplete="off">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="user.name"
                    :error="$v.user.name.$error"
                    outlined
                    dense
                    hide-details
                    prepend-inner-icon="person"
                  >
                    <template v-slot:label>
                      Name <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                  <span class="text-danger" v-if="$v.user.name.$error">
                    Name is required
                  </span>
                  <span class="text-danger" v-if="this.errors.name">
                    {{ this.errors.name[0] }}
                  </span>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="user.email"
                    :error="$v.user.email.$error"
                    outlined
                    dense
                    hide-details
                    prepend-inner-icon="email"
                    ><template v-slot:label>
                      E-mail <span class="text-danger">*</span>
                    </template></v-text-field
                  >
                  <span class="text-danger" v-if="$v.user.email.$error">
                    Email is required
                  </span>
                  <span class="text-danger" v-if="this.errors.email">
                    {{ this.errors.email[0] }}
                  </span>
                </v-col>

                <v-col cols="12" sm="6" v-if="!editUser">
                  <v-text-field
                    v-model="user.password"
                    :type="show ? 'text' : 'password'"
                    :error="$v.user.password.$error"
                    hint="At Least 8 Characters"
                    outlined
                    dense
                    hide-details
                    prepend-inner-icon="lock"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show = !show"
                    ><template v-slot:label>
                      Password <span class="text-danger">*</span>
                    </template></v-text-field
                  >
                  <span class="text-danger" v-if="$v.user.password.$error">
                    Password is Required
                  </span>
                  <span class="text-danger" v-if="this.errors.password">
                    {{ this.errors.password[0] }}
                  </span>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="user.address_line_1"
                    :error="$v.user.address_line_1.$error"
                    outlined
                    dense
                    hide-details
                    prepend-inner-icon="home"
                    ><template v-slot:label>
                      Primary Address <span class="text-danger">*</span>
                    </template></v-text-field
                  >
                  <span
                    class="text-danger"
                    v-if="$v.user.address_line_1.$error"
                  >
                    Primary Address is required
                  </span>
                  <span class="text-danger" v-if="this.errors.address_line_1">
                    {{ this.errors.address_line_1[0] }}
                  </span>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="user.address_line_2"
                    label="Secondary Address"
                    outlined
                    dense
                    hide-details
                    prepend-inner-icon="home"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="user.contact"
                    :error="$v.user.contact.$error"
                    outlined
                    dense
                    hide-details
                    prepend-inner-icon="call"
                    ><template v-slot:label>
                      Contact <span class="text-danger">*</span>
                    </template></v-text-field
                  >
                  <span class="text-danger" v-if="$v.user.contact.$error">
                    Contact is Required
                  </span>
                  <span class="text-danger" v-if="this.errors.contact">
                    {{ this.errors.contact[0] }}
                  </span>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-select
                    v-model="user.user_type"
                    :items="userTypes"
                    label="User Type"
                    outlined
                    dense
                    hide-details
                    prepend-inner-icon="group"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-file-input
                    class="mb-3"
                    @change="previewImages()"
                    v-model="user.image"
                    label="Admin Image"
                    accept="image/*"
                    small-chips
                    prepend-icon=""
                    prepend-inner-icon="mdi-file"
                    hide-details
                    outlined
                    dense
                  ></v-file-input>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-img class="w-100 max-h-300px" :src="previewImage"></v-img>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-switch
                    class="mb-5"
                    v-model="user.is_active"
                    label="Status"
                    outlined
                    dense
                    required
                  ></v-switch>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" dark @click="createOrUpdate">
            {{ editUser ? "Update" : "Save" }}
          </v-btn>
          <v-btn color="red darken-1" dark @click="close"> Cancel </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { validationMixin } from "vuelidate";
import {
  email,
  required,
  minLength,
  numeric,
  maxLength
} from "vuelidate/lib/validators";
import AdminUserService from "../../../service/user/AdminUserService";

const adminUserService = new AdminUserService();

export default {
  name: "create-or-update",
  mixins: [validationMixin],
  data() {
    return {
      show: false,
      dialog: false,
      editUser: false,
      userTypes: ["Admin", "Staff"],
      user: {
        name: "",
        email: "",
        password: "",
        image: null,
        address_line_1: "",
        address_line_2: "",
        contact: "",
        user_type: "Admin",
        is_active: true
      },
      errors: [],
      previewImage: require("@/assets/images/no-image.png")
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Users" },
      { title: "Add user" }
    ]);
  },
  validations: {
    user: {
      name: { required },
      email: { required, email },
      password: { required, minLength: minLength(8) },
      address_line_1: { required },
      contact: { required, numeric, maxLength: maxLength(10) },
      user_type: { required }
    }
  },
  methods: {
    close() {
      this.dialog = false;
      this.reset();
    },
    createAdmin() {
      this.dialog = true;
      this.editUser = false;
      this.reset();
    },
    editAdmin(user) {
      this.dialog = true;
      this.editUser = true;
      this.user = user;
      this.$set(this.user, "password", "password");
    },
    createOrUpdate() {
      this.$v.$touch();
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        if (!this.editUser) {
          this.create();
        } else {
          this.$delete(this.user, "password");
          this.update();
        }
        this.close();
      }
    },
    reset() {
      this.$v.$reset();
      this.previewImage = require("@/assets/images/no-image.png");
      this.user = {
        name: "",
        email: "",
        password: "",
        address_line_1: "",
        address_line_2: "",
        contact: "",
        user_type: "user",
        is_active: true
      };
    },
    create() {
      let formData = this.setFormData();
      adminUserService
        .store(formData)
        .then(response => {
          if (response.data.status == "OK") {
            this.$snotify.success("Add Admin user Successfully");
            this.$parent.getAllUsers();
            this.$parent.userType = "All";
          } else {
            this.$snotify.error("Something Went Wrong");
          }
        })
        .catch(err => {
          if (err.response.status == 422) {
            this.errors = err.response.data.errors;
          }
          setTimeout(() => {
            this.errors = [];
          }, 3000);
        });
    },
    update() {
      let formData = this.setFormData();
      adminUserService.update(this.user.id, formData).then(response => {
        if (response.data.status == "OK") {
          this.$snotify.success("Add Admin user Successfully");
          this.$parent.getAllUsers();
          this.$parent.userType = "All";
        } else {
          this.$snotify.error("Something Went Wrong");
        }
      });
    },
    previewImages() {
      if (this.user.image != null) {
        this.previewImage = URL.createObjectURL(this.user.image);
      } else {
        this.previewImage = require("@/assets/images/no-image.png");
      }
    },
    setFormData() {
      let formData = new FormData();
      for (let key in this.user) {
        if (
          key === "image" &&
          this.user[key] != null &&
          this.user[key] != undefined
        ) {
          formData.append("image", this.user[key]);
        } else {
          if (this.user[key]) {
            formData.append(key, this.user[key]);
          }
        }
      }
      return formData;
    }
  }
};
</script>
